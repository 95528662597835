.studentid {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .idcard1 {
    background-image: url(../images/student_idCard_images/Border_new_01.svg);
    background-repeat: no-repeat;
    width: 28.6rem;
  
  }
 
  .student-info {
    background-image: url(../images/student_idCard_images/Teks_Shape_01\ 1.png);
    background-repeat: no-repeat;
    background-size: 250px 250px;
    background-position: right;
  }
  .student-info p {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    border-bottom: 1px solid var(--erp-text-color);
    padding-bottom: 8px;
    position: relative;
    bottom: 15px;
  }
  
  .teksimg img {
    width: 170px !important;
    height: 45px !important;
    padding: 4px !important;
    margin-left: 20px !important;
  }
  
  .studid-photo img {
    margin-left: 2rem;
    margin-top: 4.3rem;
    height: 11rem;
    width: 9rem;
    padding: 3px;
    border: 2px double var(--erp-blue-200);
    border-radius: 3px;
  }
  .studid-photo p {
    font-size: 12px;
    position: relative;
    top: 20px;
    font-weight: bold;
  }
  
  .idcard2 {
    background-image: url(../images/student_idCard_images/Border_Offers.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    
    height: 350px;
  }
  
  
  .idcard2 .tekslogo {
    width: 190px;
    position: relative;
    bottom: 20px;
  
    margin-top: 0;
  }
  .idcard2 .detail{
      width: 480px;
      position: relative;
    bottom: 30px;
  }
  @media print{
    .studentid{
     position: relative;
     top: 200px;
    }

  }
  @media (max-width: 1440px) {
  }
  
  @media (max-width: 1024px) {
   
  }
  @media (max-width: 590px) {
    .student-info p {
      font-size: 8px;
      font-weight: bold;
      margin-left: 20px;
      border-bottom: 1px solid black;
      margin-bottom: 8px;
      padding: 0;
      position: relative;
      bottom: 9px;
    }
  }
  @media (max-width: 425px) {
      .student-info {
          background-image: url(../images/student_idCard_images/Teks_Shape_01\ 1.png);
          background-repeat: no-repeat;
          background-size: 150px 150px;
          background-position: right;
        }
    .idcard2 {
      margin-top: 30px;
    }
    .teksimg img {
      width: 100px;
      height: 25px;
      padding: 4px;
      margin-left: 20px;
    }
  
    .student-info p {
      font-size: 8px;
      font-weight: bold;
      margin-left: 20px;
      border-bottom: 1px solid var(--erp-black-color);
       
      padding: 0;
      position: relative;
      bottom: 9px;
    }
    .studid-photo img {
      margin-left: 0.8rem;
      margin-top: 3.3rem;
      height: 6rem;
      width: 5rem;
      padding: 3px;
      border: 0.5px double var(--erp-blue-200);
      border-radius: 3px;
    }
    .studid-photo p {
      font-size: 8px;
      position: relative;
      top: 30px;
      font-weight: bold;
    }
  
  
    .idcard2 .tekslogo {
      width: 120px;
      position: relative;
      bottom: 50px;
    
      margin-top: 0;
    }
    .idcard2 .detail{
        width: 280px;
        position: relative;
      bottom: 60px;
    }
  }
  @media (max-width: 375px) {
    .student-info p {
      font-size: 8px;
      font-weight: bold;
      margin-left: 20px;
      border-bottom: 1px solid var(--erp-black-color);
      margin-bottom: 2px;
      padding: 0;
      position: relative;
      bottom: 16px;
    }
    .studid-photo p {
      font-size: 8px;
      position: relative;
      top: 5px;
      font-weight: bold;
    }
  
    .idcard2 .tekslogo {
      width: 100px;
      position: relative;
      bottom: 60px;
    
      margin-top: 0;
    }
    .idcard2 .detail{
        width: 240px;
        position: relative;
      bottom: 70px;
    }
  }
  @media (max-width: 320px) {
    .teksimg img {
      width: 80px;
      height: 20px;
      padding: 4px;
      margin-left: 10px;
    }
  
    .student-info p {
      font-size: 6px;
      font-weight: bold;
      margin-left: 10px;
      border-bottom: 0.1px solid var(--erp-black-color);
      margin-bottom: 0px;
      padding: 0;
      position: relative;
      bottom: 28px;
    }
    .studid-photo p {
      font-size: 8px;
      position: relative;
      top: 15px;
      font-weight: bold;
    }
  
    .studid-photo img {
      margin-left: 0.8rem;
      margin-top: 2rem;
      height: 5rem;
      width: 5rem;
      padding: 3px;
      border: 0.5px double var(--erp-blue-200);
      border-radius: 3px;
    }
    .idcard2 .tekslogo {
      width: 90px;
      position: relative;
      bottom: 100px;
    
      margin-top: 0;
    }
    .idcard2 .detail{
        width: 190px;
        position: relative;
      bottom: 100px;
    }
  }
  