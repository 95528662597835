.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #405189;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.text-part {
  display: flex;
}

.gap {
  width: 30px; /* Adjust the width as needed */
}

.animated-char {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  font-size: 100px;
  font-weight: bold;
}

.orange {
  color: #ea580c;
}

.blue {
  color: rgb(255, 255, 255);
}

@media (min-width: 320px) and (max-width: 500px){
  .gap {
    width: 20px; /* Adjust the width as needed */
  }
  .animated-char {
      font-size: 40px /* Adjust the font size for smaller screens */;
      
  }
}
