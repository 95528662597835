@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

html {
  height: 100%;
}
     

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  scroll-behavior: smooth;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 20px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #ffffff4d;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}


.content {
  height: 100%;
  width: 100%;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: 1;
}

:root,
[data-erp-theme="light"] {
  /* color #4676a0*/

  --erp-primary-color: #3a4876;
  --erp-secondary-color: #eb6329;
  --erp-orange-500: #f06548;
  --erp-orange-600: #da3412;
  --erp-light-blue-color: #abb9e8;
  --erp-light-gray: #e9ebec;
  --erp-gray-color: #878a99;
  --erp-white-color: #fff;
  --erp-sky-500: #405189;
  /*edit*/
  --erp-sky-300: #7dd3fc;
  --erp-sky-400: #299cdb;
  --erp-sky-light: #dff0fa;
  --erp-yellow-color: #d19b3d;
  --erp-lightblack-color: #2e353d;
  --erp-black-color: black;
  --erp-text-mute: #878a99;
  --erp-teks-orange-color:#e96228;
  /*placeholders*/
  --erp-green-400: #0ab39c;

  --erp-green-light: #daf4f0;
  --erp-black-500: #495057;
  --erp-black-300: #495057;
  --erp-last-child-bg-color: #dde5ff;

  /* Gray / Zinc */

  --erp-gray-100: #f4f4f5;
  /*background dropdown*/
  --erp-gray-200: rgba(64, 81, 137, 0.202);
  --erp-gray-300: #d4d4d8;
  --erp-gray-400: #b3b9d0;
  /*border*/
  --erp-gray-500: #71717a;
  /*navbar icon*/
  --erp-gray-600: #52525b;
  /**/

  /* Green */

  --erp-green-100: #c7f7d8;
  --erp-green-200: #bbf7d0;
  /* --erp-green-300: #86efac; */
  --erp-green-400: #4ade80;
  --erp-green-500: #0ab39c;
  --erp-green-600: #16a34a;
  --erp-green-700: #15803d;
  /* --erp-green-800: #099885;
  --erp-green-900: #14532d;
  --erp-green-800: #166534; */
  /* --erp-green-900: #14532d; */
  --erp-light-green: #0ab39c;

  /* Red */

  /* --erp-red-100: #fde8e4;
  --erp-red-200: #fecaca; */
  --erp-red-300: #fca5a5;
  --erp-red-400: #f87171;
  --erp-red-500: #ef4444;
  --erp-red-600: #dc2626;
  /*delete-icon*/

  /* yellow */
  --erp-yellow-300: #fde047;
  --erp-yellow-400: #facc15;
  --erp-yellow-500: #eab308;
  /*fee followup*/
  --erp-yellow-600: #ca8a04;

  /* blue */

  --erp-blue-100: #4676a0;
  --erp-blue-150:#054781;
  --erp-blue-200: #2a619d;
  --erp-blue-300: #4d5a86;
  --erp-blue-dark: #10355c;
  --erp-blue-light: #839dce;
  /* --erp-blue-400: #87cefa;
  --erp-blue-500: #2385ba; */
  --erp-blue-600: #1e90ff;
  /*id-card*/
  /* --erp-blue-700: #6495ed;
  --erp-blue-800: #4682b4;
  --erp-blue-900: #00008b; */

  /* Indigo */

  /* --erp-indigo-100: #e0e7ff; */
  --erp-indigo-200: #c7d2fe;
  /**/
  /* --erp-indigo-300: #a5b4fc;
  --erp-indigo-400: #818cf8;
  --erp-indigo-500: #6366f1; */
  --erp-indigo-600: #4f46e5;
  /*ruppe*/
  --erp-indigo-700: #405189;
  /*button*/
  --erp-indigo-750: #4338ca;

  /* --erp-indigo-800: #3730a3;
  --erp-indigo-900: #312e81; */

  /* Sky */

  --erp-sky-100: #e0f2fe;
  --erp-sky-200: #bae6fd;
  /* --erp-sky-300: #7dd3fc; */
  --erp-sky-400: #38bdf8;
  --erp-sky-500: #0ea5e9;
  /*edit-icon*/
  /* --erp-sky-600: #0891b2;
  --erp-sky-700: #0369a1;
  --erp-sky-800: #075985;
  --erp-sky-900: #0c4a6e; */

  /* Violet */

  --erp-violet-100: #ede9fe;
  --erp-violet-200: #ddd6fe;
  --erp-violet-300: #c4b5fd;
  --erp-violet-400: #a78bfa;
  --erp-violet-500: #8b5cf6;
  --erp-violet-600: #7c3aed;
  --erp-violet-700: #6d28d9;
  --erp-violet-800: #5b21b6;
  --erp-violet-900: #4c1d95;

  /* Purple */

  /* --erp-purple-100: #f3e8ff;
  --erp-purple-200: #e9d5ff;
  --erp-purple-300: #d8b4fe;
  --erp-purple-400: #c084fc;
  --erp-purple-500: #a855f7;
  --erp-purple-600: #9333ea;
  --erp-purple-700: #7e22ce;
  --erp-purple-800: #6b21a8;
  --erp-purple-900: #581c87; */

  /* Changing colors */

  --erp-bg-color: #f3f3f9;
  --erp-thead-bg-color: #d9d9df;
  /*head color*/
  --erp-thead-color: #212529;
  --erp-input-border: #ced4da;
  --erp-table-bottom-border: #fff;
  --erp-card-color: #fff;
  /*bgtd color*/
  --erp-navbar-color: #fff;
  --erp-sidebar-color: #405189;
  /*table th*/
  --erp-sidebar-hover-color: rgb(43, 62, 124) 9;
  --erp-text-color: #212529;
  --erp-link-color: #4682b4;
  --erp-input-bg-color: #f3f6f9;
  --erp-tab-bg-color: #fde8e4;
  --erp-tab-two-color: #daf4f0;
  --erp-tab-three-color: #fef4e4;
  --erp-tab-four-color: #dff0fa;
  --erp-tab-five-color: #daf4f0;
  --erp-green-100: #c7f7d8;
  --erp-applicationprint-header-color: rgb(228, 236, 255);

  /* font-size */
  --erp-fs-xxs: 11px;
  --erp-fs-xs: 12px;
  --erp-fs-s: 0.8125rem;
  --erp-fs-m: 0.9375rem;
  --erp-fs-lg: 1.25rem;
  --erp-fs-7: 0.775rem;
  --erp-fs-13: 0.8125rem;
  --erp-fs-14: 14px;
  --erp-fs-16: 1rem;
  --erp-fs-17: 17px;
  --erp-fs-18: 18px;
  --erp-fs-20: 20px;
  --erp-fs-22: 22px;
  --erp-fs-24: 24px;

  /* font-weight */
  --erp-fw-100: 100;
  --erp-fw-300: 300;
  --erp-fw-400: 400;
  --erp-fw-500: 500;
  --erp-fw-600: 600;
  --erp-fw-700: 700;

  /* line-height */
  --erp-lh-100: 1.2;
  --erp-lh-200: 1.5;
  --erp-lh-300: 1.6;
  --erp-lh-400: 1.8;
  --erp-lh-500: 2;
}

/* Dark mode colors */
.darkMode,
[data-erp-theme="dark"] {
  color-scheme: dark;
  /* --erp-white-color: #1a1d21;
  --erp-sky-500: #212529;
  --erp-sky-300: #7dd3fc;
  --text-color: #fff;
  --erp-text-mute: #a3a3a3; */
  --erp-thead-bg-color: #1a1d21;
  --erp-bg-color: #1a1d21;
  --erp-card-color: #212529;
  --erp-input-border: #292e32;
  --erp-navbar-color: #292e32;
  --erp-sidebar-color: #212529;
  --erp-text-color: #fff;
  --erp-thead-color: #fff;
  /*head color*/
  --erp-link-color: #fff;
  --erp-input-bg-color: #282b2e;
  --erp-table-bottom-border: #ced4da;
  --erp-tab-bg-color: #f0654826;
  --erp-tab-two-color: #0ab39c26;
  --erp-tab-three-color: #f7b84b26;
  --erp-tab-four-color: #878a99;
  --erp-tab-five-color: #3577f126;
  --erp-applicationprint-header-color: #212529;
  --erp-last-child-bg-color: #212529;
  --erp-green-100: #292e32;
}

/* .wrap {
  background-color: var(--erp-white-color);
  width: 100vw;
  height: 100vh;
} */

/* .App {
  background-color: var(--erp-bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
} */
.app {
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--erp-bg-color);
}

.profile_bg {
  background-color: #f9f9f9;
  height: 330px;
  overflow-y: auto;
}
.teks_orange_color{
  background-color: var(--erp-teks-orange-color);
}

.font-size-xxs {
  font-size: var(--erp-fs-xxs) !important;
}

.fs-xs {
  font-size: var(--erp-fs-xs);
}

.fs-s {
  font-size: var(--erp-fs-s) !important;
}

.fs_s {
  font-size: var(--erp-fs-s);
}

.fs-m {
  font-size: var(--erp-fs-m) !important;
}

.fs-lg {
  font-size: var(--erp-fs-l);
}

.fs-7 {
  font-size: var(--erp-fs-7);
}

.fs-13 {
  font-size: var(--erp-fs-13);
}

.fs-14 {
  font-size: var(--erp-fs-14);
}

.fs-16 {
  font-size: var(--erp-fs-16);
}

.fs-17 {
  font-size: var(--erp-fs-17);
}

.fs-18 {
  font-size: var(--erp-fs-18);
}

.fs-20 {
  font-size: var(--erp-fs-20);
}

.fs-22 {
  font-size: var(--erp-fs-22);
}

.fs-24 {
  font-size: var(--erp-fs-24);
}

/* font-weight */
.fw-100 {
  font-weight: var(--erp-fw-100);
}

.fw-300 {
  font-weight: var(--erp-fw-300);
}

.fw-400 {
  font-weight: var(--erp-fw-400);
}

.fw-500 {
  font-weight: var(--erp-fw-500);
}

.fw-600 {
  font-weight: var(--erp-fw-600);
}

.fw-700 {
  font-weight: var(--erp-fw-700);
}

/* line height */
.lh-100 {
  line-height: var(--erp-lh-100);
}

.lh-200 {
  line-height: var(--erp-lh-200);
}

.lh-300 {
  line-height: var(--erp-lh-300);
}

.lh-400 {
  line-height: var(--erp-lh-400);
}

.lh-500 {
  line-height: var(--erp-lh-500);
}

p {
  color: var(--erp-text-color);
  font-weight: var(--erp-lh-400);
}

.progressbar_height {
  height: 350px;
}

.main_menu i {
  display: inline-block;
  min-width: 1.75rem;
  font-size: var(--erp-fs-l);
  line-height: inherit;
}

.mute_color {
  color: var(--erp-black-300);
}

.black_500 {
  color: var(--erp-black-500);
}

.text_underline {
  text-decoration: underline;
  color: var(--erp-black-300);
}

a {
  text-decoration: none !important;
  color: var(--erp-text-color);
}

.black_300 {
  color: var(--erp-text-color) !important;
}

.black_color {
  color: #000 !important;
}

.text-mute {
  color: var(--erp-text-mute) !important;
}

.button_color {
  color: var(--erp-white-color) !important;
}

.form-control #border_none {
  border: none;
}

/* tabs code start */
.scroll-content {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  /* Enables momentum scrolling in iOS */
}

/* .nav {
  display: inline-flex; 
} */

@media (max-width: 767px) {
  .scroll-content {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    /* Enables momentum scrolling in iOS */
  }

  .nav {
    display: inline-flex;
    /* Ensures the nav items are displayed in a row */
  }
}

/* tabs code end */
.card {
  border-radius: 0.375rem !important;
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border: 0px;
  background-color: var(--erp-card-color);
}

.card-title {
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border: 0px;
  background-color: var(--erp-card-color);
}

.card-header {
  padding: 1rem 1rem;
  background-color: var(--erp-card-color);
  border-bottom: 0px;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.form-control {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: var(--erp-card-color);
  border: 1px solid var(--erp-input-border) !important;
  border-radius: 0.25rem !important;
  color: var(--erp-text-color);
  display: block;
  font-size: var(--erp-fs-s) !important;
  font-weight: 400;
  line-height: 1.5;
  /* padding: 0.5rem 0.9rem !important; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  width: 100%;
}

.form-control.search {
  background-color: var(--erp-input-bg-color) !important;
}

.form-control.search::placeholder {
  color: var(--erp-text-mute) !important;
}

form input {
  background-color: var(--erp-input-bg-color) !important;
}

form input::placeholder {
  color: var(--erp-text-mute) !important;
}

form textarea {
  background-color: var(--erp-input-bg-color) !important;
}

form textarea::placeholder {
  color: var(--erp-text-mute) !important;
}

li {
  list-style: none;
}

h1 {
  font-weight: 600;
  font-size: 1.5rem;
  color: #000;
}

#toggle-btn {
  border: none !important;
  background: var(--erp-card-color) !important;
}

.sidebar_color {
  color: var(--erp-sidebar-color);
}

/* toggle */
.form-check-input:checked {
  background-color: var(--erp-sidebar-color) !important;
  border-color: #878a99;
  border: 5px;
}

.form-check-input:focus {
  border-color: #878a99;
  outline: 0;
  box-shadow: none;
}

/* ---- */

::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.error-input {
  border: 1px solid var(--erp-red-600) !important;
}

/* Cards */
.text_background {
  background-color: var(--erp-gray-50);
}

.text_white {
  color: #fff;
}

.bg_white {
  background-color: var(--erp-card-color) !important;
  /* box-shadow: 0 0 0 var(--erp-text-mute) !important; */
}

.input_bg_color {
  background-color: var(--erp-card-color) !important;
  /* border: 1px solid var(--erp-text-mute) !important; */
  color: var(--erp-text-color) !important;
}

.input_bg_color::placeholder {
  color: var(--erp-text-mute) !important;
}

.list_bg_white {
  background-color: var(--erp-card-color) !important;
  color: var(--erp-text-color) !important;
}

.text_danger {
  color: var(--erp-red-500) !important;
}

.text_color {
  color: var(--erp-text-color) !important;
}

/* button css */
.btn-label {
  position: relative;
  padding-left: 44px;
}

.btn-label.right {
  padding-left: 0.9rem;
  padding-right: 44px;
}

.btn-label .label-icon {
  position: absolute;
  width: 35.5px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-color: #0ab39c;
  background-color: #0ab39c;
  color: #fff;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-label.right .label-icon {
  right: 0px;
  left: auto;
}

.btn-label:hover {
  color: #fff;
  background-color: #2da494 !important;
  border-color: #2da494;
}

.page_color {
  background-color: #3a4876 !important;
  color: white !important;
}

.btn_primary {
  background-color: var(--erp-sidebar-color) !important;
  color: #fff !important;
}

.btn_primary:hover {
  background-color: var(--erp-sidebar-color);
  color: #fff;
}

/* settings tabs */
.bg-danger-subtle {
  background-color: #a0add8 !important;
}

.card_animate {
  transition: all 0.3s linear;
  --webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  /* box-shadow: 0px 5px 0px 5px solid var(--erp-gray-color); */
}

.card_animate:hover {
  transform: translateY(-7px);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  flex-basis: auto !important;
  flex-grow: 1;
  text-align: center;
}

/* tabs inside of fee details page */
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px !important;
}

.nav-link {
  border: none !important;
  background-color: var(--erp-card-color) !important;
  color: var(--erp-text-color) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: none !important;
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* border-left: 3px solid var(--erp-violet-600) !important;*/
  border-bottom: 3px solid var(--erp-sidebar-color) !important;
  /* border: 1px solid var(--erp-sidebar-color) !important; */
  /* box-shadow: var(--erp-violet-600) 0px 4px 8px -2px,
    var(--erp-violet-600) 0px 0px 0px 1px !important; */
}

/* .datepicker_input::-webkit-calendar-picker-indicator {
  visibility: hidden;
  opacity: 0;
} */

.light-blue-color {
  color: var(--erp-blue-light);
}

.text_yellow {
  color: var(--erp-yellow-500);
}

/* dashboard css */
.border-dashed {
  border-style: dashed !important;
}

/* table */

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #a0add8 !important;
  box-shadow: none !important;
}

.date-bg.form-control {
  border: none !important;
}

.date-bg.form-control:focus {
  border: none !important;
}

main {
  min-height: 100vh;
  margin-bottom: -60px;
}

.footer,
.push {
  text-align: center;
  padding: 20px;
}


/* footer */

.footer {
  background-color: var(--erp-card-color);
}

.footer-text {
  color: var(--erp-text-mute)
}

.footer_alignment {
  display: flex;
  justify-content: space-between;
  align-items: end;
  text-align: justify;
}

.input-group-text {
  border-bottom-right-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.bg_calendar {
  background-color: #3a4876;
  border-color: #3a4876;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: var(--erp-primary-color) !important;
  color: var(--erp-white-color) !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
span.flatpickr-weekday,
.flatpickr-day.endRange {
  background-color: var(--erp-primary-color) !important;
  border-color: var(--erp-primary-color) !important;
  color: var(--erp-white-color) !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: var(--erp-fs-13);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--erp-primary-color) !important;
  color: var(--erp-white-color) !important;
  font-size: var(--erp-fs-13);
}

.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-next-month svg {
  color: var(--erp-white-color) !important;
}

.numInputWrapper span.arrowUp,
.numInputWrapper span.arrowDown {
  color: var(--erp-white-color) !important;
}

.date_input {
  padding: 0px !important;
}

/*inactive*/
.style {
  opacity: 0.5;
}

/* page title  */
.page_title_box {
  padding: 10px 1rem;
  background-color: var(--erp-white-color);
  -webkit-box-shadow: var(--vz-page-title-box-shadow);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  border-bottom: 1px solid none;
  /* margin: -23px -1.5rem 1.5rem -1.5rem; */
  font-weight: 600;
  color: var(--erp-black-300);
  font-size: 15px !important;
  /* text-transform: uppercase; */
}

.dashboard-tables {
  height: 210px;
}

.react-datepicker__month-container {
  float: left;
  z-index: 9999 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.buttons_alignment {
  display: flex;
  justify-content: end;
  align-items: end;
}

/* .flatpikr-dashboard {
  width: 210px !important;
} */

table {
  width: 100%;
  border-collapse: collapse;
}

.date_input_color {
  color: var(--erp-text-mute) !important;
  font-weight: 500 !important;
}

select:invalid,
select option[value=""] {
  color: var(--erp-text-mute) !important;
  font-weight: 500 !important;
}

select option {
  color: var(--erp-text-color) !important;
}

.apexcharts-text tspan {
  font-family: inherit;
  font-size: 12px;
}

/* responsive start */
@media (max-width: 728px) {
  .calender_icon {
    height: 32px !important;
  }

  .buttons_alignment {
    justify-content: start;
    align-items: start;
    display: contents;
  }


}

@media screen and (max-width: 425px) {
  .margin_top_12 {
    margin-top: 12px;
  }

  .footer-text {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .calendar-icon {
    height: 32px !important;
  }
}

@media screen and (max-width: 320px) {
  .flatpikr-dashboard {
    width: 180px !important;
    font-size: 11px !important;
  }

  .footer-text {
    font-size: var(--erp-fs-xxs);
  }
}

#apexchart-example {
  font-size: 1px;
}

/* responsive end */
.progress {
  height: 10px;
  /* Set the height of the progress bar */
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: var(--erp-sidebar-color) !important;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  transition: var(--bs-progress-bar-transition);
}

input[type="date"] {
  justify-content: space-between;
  display: flex;
}

input[type="time"] {
  justify-content: space-between;
  display: flex;
}

.certificate-date {
  display: flex !important;
  justify-content: left !important;
}

input[type="date"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f073";
  color: var(--erp-text-mute);
  padding: 0 1px;
}

input[type="time"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f017";
  color: var(--erp-text-mute);
  padding: 0 1px;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* td:hover {
  cursor: pointer !important;
} */
.form-check .form-check-input:hover {
  cursor: pointer !important
}

.cursor-pointer {
  cursor: pointer;
}

.pagination-select {
  padding: 2.3px !important;
  width: 60px !important;
  margin-top: 5px;
}


@media (min-width: 320px) and (max-width: 500px) {
  .w_100 {
    width: 100% !important;
  }
}

.table> :not(caption)>*>* {
  background-color: transparent !important;
}

.collapse-icon {
  transition: transform 0.3s ease;
}

.collapsed .collapse-icon {
  transform: rotate(-180deg);
}

.over-btn {
  border-radius: 1px solid;
}

.form-switch .form-check-input {
  padding-left: 0em !important;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  /* padding-left: 1.5em; */
  /* margin-bottom: .125rem;
  text-align: center;
  align-items: center; */
  /* display: flex; */
  /* justify-content: start; */
}

.roles-table {
  height: 400px;
  /* Set the height of the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* dropdown active */
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--erp-light-gray) !important;
}

/* profile page csss */
.image_bg {
  background: linear-gradient(to top, #171e32, #405189);
  border-radius: 7px;
}

/* nav pills tabs */

.display_no {
  height: 20px !important;
}
.share-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background: white;
  padding-top: 30px; 
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 27%;
  max-width: 600px; 
  height: 45%; 
  max-height: 500px; 
  position: relative; 
}
.popup-content h3{
  font-size: 20px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid rgb(168, 166, 166); */
  
}
.close-button {
  background: #f5f5f5;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;
}

.share-buttons a {
  margin: 0 10px;
}

.share-icon {
  font-size: 60px;
  color: #333;
}

.share-icon:hover {
  color: #007bff;
}



.share-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px; 
  background: white; 
  padding: 10px; 
  display: flex;
  align-items: center;
  position: relative; 
  overflow: hidden; 
  white-space: nowrap; 
}

.copy-icon {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px; 
  position: relative; 
}

.copied-popup {
  background: #000; 
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  bottom: 10%; 
  left: 80%; 
  transform: translateX(-50%);
  white-space: nowrap; 
  z-index: 000; 
  font-size: 12px;  
}

.share-box a {
  display: inline-block; 
  max-width: calc(100% - 50px); 
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevents text wrapping */
}

@media screen and (device-width: 1440px) {
  .table-container-two {
    height: 572px !important;
    overflow-y: auto;
  }
}

@media screen and (device-width: 1024px) {
  .card_height {
    height: 130px;
  }

  .fs-22 {
    font-size: 16px;
  }

  .bottom_space {
    margin-bottom: 19px;
  }
}

@media only screen and (min-width : 1025px) {}


@media only screen and (max-width : 1024px) {
  .profile_view {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width : 768px) {
  .profile_view {
    margin-bottom: 20px;
  }
}

.table-container-one {
  height: 476px;
  overflow-y: auto;
}

.table-container-two {
  height: 554px;
  overflow-y: auto;
}

@media (min-width: 320px) and (max-width: 500px) {
  .button-res {
    width: 100% !important;
  }
}

/* df khgjf  */
.overflow_refundview {
  overflow: auto;
  max-width: 100px;
  /* Adjust this value as needed */
  white-space: nowrap;
  /* Prevent text wrapping */
}

.disabled {
  cursor: not-allowed;
}

.offcanvas {
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  visibility: visible;
}

.offcanvas.show {
  visibility: visible;
}

.offcanvas:not(.show) {
  visibility: hidden;
  transform: translateX(100%);
}

@media (min-width: 320px) and (max-width: 500px) {
  .apexcharts-text tspan {
    font-size: 7px !important;
  }
}

/* Add this CSS to your stylesheet */

/* Custom CSS to override ApexCharts data label font size */
.apexcharts-datalabel {
  font-size: 12px !important;
  color: #fff !important;
  position: absolute;
  top: 100px !important;
}


.auth-page-wrapperr .auth-page-contentt {
  padding-bottom: 60px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.position-relativee {
  position: relative !important;
}

.error-500 .error-500-img {
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.error-500 .title {
  font-size: 220px;
}

.text-mutedd {
  opacity: 1;
  color: #878a99 !important;
  font-size: 13px;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.w-75 {
  width: 80% !important;
}

@keyframes slightMove {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(18px);
  }

  100% {
    transform: translateX(0);
  }
}

.error-500-img {
  animation: slightMove 2s ease-in-out infinite;
}

.tri-state-toggle {
  background: rgba(165, 170, 174, 0.25);
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  flex-direction: row;
  transition:all 0.3s ease-in-out;
}

.tri-state-toggle-button {
  border-radius: 22px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  background-color: transparent;
  border: 0px solid transparent;
  margin: 2px;
  color: #727C8F;
  cursor: pointer;

  /*    -webkit-transition: all 0.5s ease-in-out;
-moz-transition:    all 0.5s ease-in-out;
-ms-transition:     all 0.5s ease-in-out;
-o-transition:      all 0.5s ease-in-out; */
  transition: all 0.5s ease;
}

.tri-state-toggle-button.active {
  background-image: linear-gradient(-180deg, #a2aaca  100%, #a2aaca  100%, #a2aaca 100%);
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: #6744B1;
  font-weight: 500;
  transition: all .5s ease-in;

}


.tri-state-toggle-button:focus {
  outline: none;
}
.tri-state-toggle-button.active.red {
  background-image: linear-gradient(-180deg, #FF4D4D 100%, #FF4D4D 100%, #FF7575 100%);
  border: 1px solid rgba(255, 77, 77, 0.6);
  color: white;
}

.tri-state-toggle-button.active.green {
  background-image: linear-gradient(-180deg, #4CAF50 100%, #4CAF50 100%, #66BB6A 100%);
  border: 1px solid rgba(76, 175, 80, 0.6);
  color: white;
}
.persons{
  width: 100%!important;
  display: flex!important;
  justify-content:start!important;
}
.btn-width{
  height: 30px;
}

@media only screen and (max-width : 320px) {
  .responsive-chart {
   width: 210px!important;
  
  }
  .apexcharts-datalabel {
    font-size: 8px !important;
    color: #fff !important;
    position: absolute;
    top: 100px !important;
  }
}
.box{
  box-shadow: 0 14px 40px rgba(30, 10, 58, .1);
  background-color: #fff;
  padding: 24px 24px 20px;
  border-radius: 20px;
}
.animated-text {
  display: inline-block;
  overflow: hidden;
}

.animated-text span {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
/* .resizable-th {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 10px;
  cursor: default;
}

.resizable-th .resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; 
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}

.table-width {
  table-layout: fixed; 
} */


.table-width {
  table-layout: fixed;
}

.table-width th,
.table-width td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resizable-th {
  position: relative;
}

.resizer {
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  user-select: none;
}
/* .modal-body {
  max-height: 70vh;
} */
.description-scroll {
  max-height: 200px;
  overflow-y: auto;
}
@media (min-width: 320px) and (max-width: 500px) {
  .job-respons{
    margin-top: 90px;
  }
  .jobwidth{
    width: 100% !important;;
  }
}
.profile-bg {
  background-color: #f9f9f9;
  overflow-y: auto;
}

