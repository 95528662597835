.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.fee-nav {
  display: contents !important;
}


/* @media screen and (max-width: 425px) {
  .fee-nav{
    width: 200px !important;
    overflow-x: scroll!important;
    scrollbar-width: thin!important;
  }

} */