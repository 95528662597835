.avatar-md {
    height: 4.5rem;
    width: 4.5rem;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--erp-white-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    
}
.text-danger {
    --vz-text-opacity: 1;
    color: var(--erp-orange-color);
}