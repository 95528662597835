.border-infoz {
    position: relative; /* Ensures the inner border is positioned relative to this container */
    margin-top: 15px;
    border-bottom: 10px solid #db622e; /* Existing orange border */
}

.border-infoz .border-blue {
    position: absolute; /* Position it absolutely within the .border-infoz */
    bottom: -18px; /* Position it just below the existing orange border */
    left: 0;
    width: 100%;
    height: 3px; /* Height of the new blue border */
    background-color: #2a619b; /* Color of the new border */
}
.address-iep{
    background-color: #2d6cb0;
    color: white!important;
   
}

.content-width {
    
    text-align: justify; /* Justifies the text */
    font-family: "Crimson Text", serif;
    font-weight: 400;
  
}
.iep-color{
    color: #2d6cb0!important;
    font-weight: 600!important;
}
.no-bullets {
    list-style-type: none; /* Removes bullet points */
    padding-left: 0; /* Removes indentation */
  }
  .no-line-height h6 {
    margin: 0; /* Removes default margin */
    line-height: 1; /* Sets line-height to be tight */
    font-size: 18px;
    font-weight: 500;
  }
  .iep-font {
    font-size: 17px;
    font-weight: 500;
  }
  .iep-sign{
    width: 150px;
    height: 40px;
  }
