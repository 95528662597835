

.info-col {
    background-color: var(--erp-sky-400);
    color: var(--erp-white-color)
}

.info-col:hover {
    background-color: var(--erp-sky-400);
    color: var(--erp-white-color)
}

.error-bg {
    background-image:  url(../images/errorimage/Banner_01.png);
    background-position: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}

.coming-soon-text {
    font-weight: var(--erp-fw-700);
    text-transform: uppercase;
    color: var(--erp-white-color);
    text-shadow: 3px 4px var(--erp-green-400);
}

.back-col {
    background-color: var(--erp-green-400);
}




.bg_light{
    background-color: var(--erp-white-color);
}

.fs-12 {
    font-size: var(--erp-fs-s);
  
} 